import React from "react";
import {
  RouterProvider,
} from "react-router-dom";

import './App.scss';
import { appRouter } from './App.router';
import { HeaderComponent } from './Header.component';
import { FooterComponent } from './Footer.component';

function AppComponent(): JSX.Element {
  return (
    <React.Fragment>

      <div className='container'>
        <HeaderComponent></HeaderComponent>
      </div>

      <RouterProvider router={appRouter} />

      <div className='container'>
        <FooterComponent></FooterComponent>
      </div>
    </React.Fragment>
  );
}

export default AppComponent;
