export function FooterComponent(): JSX.Element {
    return (
        <footer>
            <h3>
                Kontakt
            </h3>
            <ul>
                <li>Piotr Kosewski</li>
                <li>(+48) 729 859 085</li>
                <li>Legionowo, Wieliszew, Serock i okolice</li>
                <li>Pon-Pt 10:00 - 20:00</li>
                <li>serwis@speedelektron.com</li>
            </ul>
        </footer>
    );
}