import React from "react";
import { UEComponent } from "./UE.component";
import "./Header.component.scss";

import logo from "./img/logo.jpg"

export function HeaderComponent() {
    return (
        <React.Fragment>
            <UEComponent></UEComponent>

            <header>
                <div className="col-6 se-logo">
                    <img src={logo} alt="" />
                </div>
            </header>
        </React.Fragment >
    );
}