import React from "react";
import "./UE.component.scss";
import prow from "./img/prow.jpg";
import eu_flag from "./img/EU_flag.png";
import leader from "./img/leader.jpg";
import zalew from "./img/zalew.jpg";

export function UEComponent() {
    return (
        <React.Fragment>
            <div className="ue-container">
                <div className="col-12 ue-body">
                    <p>„Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich:
                        Europa inwestująca w obszary wiejskie”.</p>
                    <p>
                        Operacja pn. "Podjęcie działalności gospodarczej w zakresie mobilnych usług napraw elektroniki”
                    </p>
                    <p>mająca na celu: „Podjęcie działalności gospodarczej w zakresie mobilnych usług napraw elektroniki
                        oraz utrzymanie jej w okresie związanym z celem”
                    </p>
                    <p>
                        Współfinansowana jest ze środków Unii Europejskiej w ramach poddziałania
                        „Wsparcie na wdrażanie operacji w ramach strategii rozwoju lokalnego kierowanego przez społeczność”
                        Programu Rozwoju Obszarów Wiejskich na lata 2014 - 2020.
                    </p>
                    <p>
                        Operacja realizowana w ramach strategii rozwoju lokalnego kierowanego przez społeczność wdrażanej przez LGD Zalew Zegrzyński.
                    </p>
                </div>
                <div className="col-12 ue-logos">
                    <img src={eu_flag} />
                    <img src={leader} />
                    <img src={zalew} />
                    <img className="prow" src={prow} />
                </div>
            </div>
        </React.Fragment>
    )
}