import React from 'react';
import { CategoryModel } from './Category.model';
import { HeroComponent } from './Hero.component';

import pc from "./img/pc.svg";
import software from "./img/software.svg";
import heavyDuty from "./img/heavyDuty.svg";
import automatics from "./img/automatics.svg";
import audio from "./img/audio.svg";
import household from "./img/household.svg";
import arrow from "./img/right-arrow.svg"

export function MainPageComponent() {
    const categories: CategoryModel[] = [
        {
            title: 'Oprogramowanie',
            subtitle: 'Instalacja lub naprawa systemu. Przenoszenie plików.',
            iconSrc: software,
            link: ''
        },
        {
            title: 'Sprzęt komputerowy',
            subtitle: 'Naprawa oraz czyszczenie PC, laptopów, drukarek.',
            iconSrc: pc,
            link: ''
        },
        {
            title: 'Sprzęt AGD',
            subtitle: 'Naprawa pralek, zmywarek, kuchenek indukcyjnych, piekarników i okapów.',
            iconSrc: household,
            link: ''
        },
        {
            title: 'Sprzęt RTV',
            subtitle: 'Naprawa wzmacniaczy, radia samochodowych lub domowych.',
            iconSrc: audio,
            link: ''
        },
        {
            title: 'Elektronarzędzia',
            subtitle: 'Naprawa wiertarek, młotów, wkrętarek, pił, frezarek',
            iconSrc: heavyDuty,
            link: ''
        },
        {
            title: 'Automatyka, sterowniki',
            subtitle: 'Naprawa lub konfiguracja alarmu, pieca, oświetlenia, bram garażowych',
            iconSrc: automatics,
            link: ''
        },
    ];

    const categoriesRender = categories.map((c: CategoryModel) =>
        <React.Fragment>
            <div className='category-container' onClick={() => handleCategoryClick(c)}>
                <img className='icon-topic' src={c.iconSrc}></img>
                <div className='category-text'>
                    <h4>{c.title}</h4>
                    <h5>
                        {c.subtitle}
                    </h5>
                </div>
                <img className='icon-arrow' src={arrow}></img>
            </div>
        </React.Fragment>
    );

    function handleCategoryClick(c: CategoryModel): void {

        return;
    }

    return (
        <React.Fragment>

            <div className="dark">
                <div className='container'>
                    <HeroComponent></HeroComponent>
                </div>
            </div>

            <div className='container'>

                <section className='categories-section'>
                    <h3>
                        Wybierz kategorie usług
                    </h3>
                    <div className="categories-list">
                        {categoriesRender}
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}