export function HeroComponent() {
    return (
        <section className='se-hero'>
            <div className="promotion">

                <div className="promotion-content">

                    <p className="promotion-heading">SUPER<br /> PROMOCJA</p>
                    <p className="promotion-discount">-10%</p>
                    <p className="promotion-info">Dla seniorów na usługi naprawy elektroniki.</p>
                </div>

            </div>


            <h2>
                <i className='bi bi-geo-alt'></i>
                <span className='se-hero__location'>Legionowo, Wieliszew, Serock</span>
            </h2>
            <h1>
                Twój lokalny serwis elektroniki użytkowej
            </h1>

            <p className="subtitle">Masz pytanie, zadzwoń:</p>

            <button className='btn btn-primary' onClick={() => location.href = 'tel:729859085'}>
                <span>
                    <i className="bi bi-telephone"></i>
                    <span>
                        729 859 085
                    </span>
                </span>
            </button>
        </section>
    );
}