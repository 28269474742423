import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import { MainPageComponent } from './MainPage.component';
import { CategoryComponent } from './Category.component';


export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <MainPageComponent />,
  },
  {
    path: "/category/:id",
    element: <CategoryComponent />,
  },
]);
